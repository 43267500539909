<template>
    <HeroPage v-if="pageData" :data="pageData" />
    <PageLayoutBuilder v-if="pageData" :data="pageData" />
</template>

<script>
// import { fetchMediaList } from "@streampac.io/chef_sp_1";
// @import component
//import HeroVideo from "@/components/HeroVideo";
import HeroPage from "@/components/HeroPage";

// import PricingCta from "@/components/PricingCta";
import PageLayoutBuilder from "@/components/PageLayoutBuilder";

import {
    fetchPageBySlug,
} from "@streampac.io/chef_sp_1";

export default {
    name: "home",
    inject: ["isAuthenticated"],
    // metaInfo() {
    //     return {
    //         title:
    //             "Home | LEAD Hockey",
    //         description:
    //             "",
    //     };
    // },
    data() {
        return {
            pageData: null,
            fetchDataParams: {
                paging: {
                    pageNumber: 1,
                    pageSize: 6,
                },
            },
        };
    },
    components: {
        //HeroVideo,
        HeroPage,
        PageLayoutBuilder,
    },
    watch: {
        isAuthenticated() {
            this.pageData = null;
            setTimeout(() => {
                this.fetchPage();
            }, 50);
        },
    },
    created() {
        this.fetchPage();
    },
    methods: {
        fetchPage() {
            fetchPageBySlug(this.isAuthenticated, "category-" + this.$route.params.slug).then(
                (response) => {
                    this.pageData = response;
                }
            );
        },
    },
};
</script>

<style lang="scss" scoped>
// @import styles
@import "./index.scss";
</style>
